@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Inter', sans-serif;
    scroll-behavior: smooth;
}

html, body {
    margin: 0;
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
}

#root {
    min-height: 100vh;
    width: 100vw;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url( 'react-notifications/lib/notifications.css');
